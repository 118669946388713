import React from 'react';
import './common.scss';
import './channel.scss';
import SearchBox from './searchBox.jsx';

let FakeItem = ({ className }) => {
	return <div className={className} />;
};

class Channel extends React.Component {
	render() {
		return (
			<div className="loading-container">
				<div className="ad"></div>
				<section className="searchBox-container">
					<section className="tab">
						<section className="left"></section>
					</section>
					<SearchBox />
					<section className="botton-submit"></section>
				</section>

				<div className="fake-low-price-map">
					<FakeItem className="r0c0" />
					<FakeItem className="r1c0" />
					<FakeItem className="r2c0" />
					<FakeItem className="r2c1" />
					<FakeItem className="r3c0" />
					<FakeItem className="r3c1" />
					<FakeItem className="r4c0" />
					<FakeItem className="r4c1" />
					<FakeItem className="r5c0" />
					<FakeItem className="r5c1" />
					<FakeItem className="map" />
				</div>
			</div>
		);
	}
}

export default Channel;
