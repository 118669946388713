/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import { NextPage, GetServerSideProps } from 'next';
import Head from 'next/head';
import BasePage from '@/src/components/common/BasePage';

import Skeleton from '@/src/components/loading/channel';
import Channel from '@/src/containers/channelv2/ChannelContainer/async';

import '../../styles/normalize.css';
import '../../../public/style/index.css';
import '../../../public/style/channel.css';
import '../../../public/style/searchbox.css';
import '../../styles/channelV2/override/main.scss';

const index: NextPage<Record<string, any>> = ({ setCurrentPageId, renderData }) => {
	const { domesticSearch } = renderData;
	const [firstRender, setFirstRender] = useState(true);
	useEffect(() => {
		setFirstRender(false);
	}, []);

	const pageId = useMemo(() => (domesticSearch ? '101023' : '10320669438'), [domesticSearch]);

	useEffect(() => {
		if (!firstRender) {
			setCurrentPageId(pageId);
		}
	}, [firstRender, pageId]);

	return (
		<>
			<Head>
				<meta name="applicable-device" content="pc" />
				<meta name="mobile-agent" content="format=html5;url=https://m.ctrip.com/html5/flight/" />
				<link
					rel="alternate"
					media="only screen and (max-width: 640px)"
					href="https://m.ctrip.com/html5/flight/"
				/>
				<link rel="canonical" href="https://flights.ctrip.com/" />
				<meta httpEquiv="Cache-Control" content="no-transform " />
				{domesticSearch ? (
					<>
						<meta
							name="description"
							content="携程机票官网提供飞机票查询,机票预订和特价机票查询,打折机票查询服务。携程同时动态提供航班查询,时刻表查询,机票查询时刻表票价,实时起降查询,以东方航空,中国南方航空,中国国航,深圳航空,厦门航空,海南航空,四川航空,春秋航空官方网站特价机票查询,是您网上订机票的首选。"
						/>
						<meta
							name="keywords"
							content="机票,机票预订,机票查询,飞机票,飞机票查询,特价机票,打折机票,订机票,网上订票,机票价格查询,特价机票查询,飞机票价查询"
						/>
						<title>【携程机票】飞机票查询,机票预订,机票价格查询,打折特价机票</title>
					</>
				) : (
					<>
						<meta
							name="description"
							content="携程国际飞机票提供国际飞机票查询,国际机票预订和国际特价机票查询,国际打折机票查询服务。携程国际机票同时提供国际航班查询,国际机票查询时刻表及票价,是您网上预订特价国际机票,打折便宜机票的首选。"
						/>
						<meta
							name="keywords"
							content="国际机票,国际机票预订,国际机票查询,国际飞机票,国际飞机票查询,国际特价机票,国际打折机票,订国际机票,国际机票价格查询,国际特价机票查询,国际飞机票价查询,国际航班"
						/>
						<title>国际机票查询,国际航班预订,国际特价机票价格查询【携程国际机票】</title>
					</>
				)}
			</Head>
			{firstRender ? <Skeleton /> : React.createElement(Channel)}
		</>
	);
};

index.displayName = 'page(channel)';

export const getServerSideProps: GetServerSideProps = async (context) => {
	const { query, req } = context;
	const { headers } = req;
	const { renderData, aresModules, aresManifest, aresAssetPrefix } = headers;

	return {
		props: {
			...query,
			renderData,
			aresModules,
			aresManifest,
			aresAssetPrefix,
		},
	};
};

export default BasePage({})(index);
